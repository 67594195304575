import React, {useEffect} from "react";
import '../styling/About.css';
import Headshot3 from '../images/Headshot3.png'


const About = () =>{
    useEffect(() => {
        document.title="Alexis Boucouvalas | About"
    }, [])
    return(
        <div className="About">
            <div className="big-container">
                <div className="about-container">
                    <h1 className="greet">MY STORY</h1>
                    <div className="pic-container">
                        <img className='headshot3' src={Headshot3} alt="Headshot"></img>
                    </div>
                    <div className="about-text">
                        <p>HI, I'M ALEXIS AND I'M A SOFTWARE ENGINEER! I'M CURRENTLY A FULL-TIME COLLEGE STUDENT AT UNH IN MY SENIOR YEAR, PURSUING A BACHELOR'S DEGREE IN COMPUTER INFORMATION SYSTEMS. I ORIGINALLY STARTED AS A BUSINESS MAJOR AND REALLY WANTED TO DO SOMETHING WITH ART. I'VE ALWAYS BEEN DRAWN TO ART, CREATIVITY, AND PROBLEM SOLVING.</p>
                        <p>WHEN I WAS PUT INTO AN INTRO TO WEB DESIGN CLASS, I JUST ABSOLUTELY FELL IN LOVE WITH PROGRAMMING. I REALIZED THAT SOFTWARE ENGINEERING WAS THE PERFECT BLEND OF MY PASSIONS. IT ALLOWED ME TO USE MY CREATIVITY TO DESIGN AND BUILD INTERACTIVE AND VISUALLY APPEALING APPLICATIONS, WHILE ALSO CHALLENGING ME TO SOLVE COMPLEX PROBLEMS THROUGH LOGICAL THINKING.</p>
                        <p>I'VE DISCOVERED THAT SOFTWARE ENGINEERING IS A FORM OF ART IN ITSELF. WRITING CODE REQUIRES THE SAME LEVEL OF CREATIVITY AND EXPRESSION AS PAINTING A PICTURE OR COMPOSING MUSIC. IT'S ABOUT CRAFTING ELEGANT SOLUTIONS AND CREATING SOMETHING FUNCTIONAL AND BEAUTIFUL FROM SCRATCH.</p>
                        <p>THE PROBLEM-SOLVING ASPECT OF SOFTWARE ENGINEERING ALSO RESONATES DEEPLY WITH ME. I ENJOY THE PROCESS OF ANALYZING ISSUES, BREAKING THEM DOWN INTO SMALLER COMPONENTS, AND FINDING INNOVATIVE WAYS TO SOLVE THEM. EVERY BUG OR CHALLENGE I ENCOUNTER IS LIKE A PUZZLE THAT NEEDS TO BE SOLVED, AND THE SATISFACTION I GET FROM FIGURING IT OUT IS UNMATCHED.</p>
                        <p>I WAS ENROLLED IN FLATIRON'S FULL-TIME SOFTWARE ENGINEERING BOOTCAMP, WHICH ALLOWED ME TO DEEPEN MY TECHNICAL SKILLS AND EXPAND MY KNOWLEDGE. IT WAS AN EXCITING JOURNEY THAT NOT ONLY FURTHERED MY UNDERSTANDING OF CODE, BUT ALSO GAVE ME THE OPPORTUNITY TO COLLABORATE WITH OTHER PASSIONATE INDIVIDUALS AND WORK ON REAL-WORLD PROJECTS.</p>
                        <p>MY GOALS ARE TO GET THROUGH MY LAST YEAR OF COLLEGE, FINISH STRONG, IMPROVE ON AND SOLIDIFY MY TECHNICAL SKILLS, AND KICK-START MY CAREER IN SOFTWARE DEVELOPMENT. I WANT TO COMBINE MY LOVE FOR ART, CREATIVITY, AND PROBLEM SOLVING TO BUILD INNOVATIVE AND USER-FRIENDLY SOFTWARE APPLICATIONS THAT MAKE A POSITIVE IMPACT IN PEOPLE'S LIVES.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About