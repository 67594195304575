// STAMPS
import artNouveau from './graphicDesign/stamps/ArtNouveau.png'
import modernist from './graphicDesign/stamps/Modernist.png'
import futurism from './graphicDesign/stamps/Futurism.png'
import bauhaus from './graphicDesign/stamps/Bauhaus.png'
import international from './graphicDesign/stamps/International.png'
import postModern from './graphicDesign/stamps/PostModern.png'
import punk from './graphicDesign/stamps/Punk.png'

// ENERGY DRINKS
import americanTrad from './graphicDesign/energyDrinks/AmericanTraditional.png'
import fineline from './graphicDesign/energyDrinks/Fineline.png'
import japanese from './graphicDesign/energyDrinks/Japanese.png'

// MISC
import psycho from './graphicDesign/PsychoRedesign.png'
import advocacy from './graphicDesign/AdvocacyPoster.png'
import magazine from './graphicDesign/MagazineSpread.png'
import tradingCard from './graphicDesign/TradingCard.png'
import stationary from './graphicDesign/Stationary.png'
import merch from './graphicDesign/Merch.png'



const design = [
    {
        id: 1,
        title: 'STAMP #1: ART NOUVEAU',
        date: 'FEBRUARY 25, 2024',
        description: 'THE FIRST STAMP IN A SERIES OF SEVEN STAMPS, SHOWCASING MY IDEA OF WHAT THE AIR JORDAN LOGO WOULD LOOK LIKE THROUGH VARIOUS DESIGN AND ARTISTIC MOVEMENTS THROUGHOUT HISTORY.',
        image: artNouveau
    },
    {
        id: 2,
        title: 'STAMP #2: MODERNIST',
        date: 'MARCH 4, 2024',
        description: 'THE SECOND STAMP IN A SERIES OF SEVEN STAMPS, SHOWCASING MY IDEA OF WHAT THE AIR JORDAN LOGO WOULD LOOK LIKE THROUGH VARIOUS DESIGN AND ARTISTIC MOVEMENTS THROUGHOUT HISTORY.',
        image: modernist
    },
    {
        id: 3,
        title: 'PSYCHO MOVIE POSTER REDESIGN',
        date: 'APRIL 4, 2023',
        description: "A REVAMP OF THE ORIGINAL POSTER FOR ALFRED HITCHCOCK'S 'PSYCHO'.",
        image: psycho
    },
    {
        id: 4,
        title: 'ADICTION ADVOCACY POSTER',
        date: 'FEBRUARY 25, 2024',
        description: 'AN ADVOCACY POSTER FOCUSED ON THE STRUGGLES OF SUBSTANCE ABUSE. I AIMED TO ILLUSTRATE HOW ADDICTION CAN PREVENT INDIVIDUALS FROM EXPERIENCING BEAUTIFUL AND IMPORTANT MOMENTS IN THEIR LIVES.',
        image: advocacy
    },
    {
        id: 5,
        title: 'MAGAZINE SPREAD REDESIGN',
        date: 'APRIL 6, 2023',
        description: "A REDESIGN OF ALAN ALDA'S SPREAD 'ALAN ALDA IS OBSESSED WITH SCIENCE' IN AARP THE MAGAZINE JUNE/JULY 2020.",
        image: magazine
    },
    {
        id: 6,
        title: 'ISTVAN OROSZ TRADING CARD',
        date: 'MAY 4, 2023',
        description: "DRAWING INSPIRATION FROM ISTVAN OROSZ AND HIS WORK, I'VE CRAFTED A TRADING CARD IN HIS HONOR.",
        image: tradingCard
    },
    {
        id: 7,
        title: 'STAMP #3: FUTURISM',
        date: 'MARCH 11, 2024',
        description: 'THE THIRD STAMP IN A SERIES OF SEVEN STAMPS, SHOWCASING MY IDEA OF WHAT THE AIR JORDAN LOGO WOULD LOOK LIKE THROUGH VARIOUS DESIGN AND ARTISTIC MOVEMENTS THROUGHOUT HISTORY.',
        image: futurism
    },
    {
        id: 8,
        title: 'ENERGY DRINK #1: AMERICAN TRADITIONAL',
        date: 'MARCH 12, 2024',
        description: "THE FIRST DESIGN IN A SERIES OF THREE. I CREATED A TATTOO INSPIRED ENERGY DRINK BRAND WITH THREE CAN DESIGNS BASED ON A STYLE OF TATTOOS. THIS IS 'SAILOR'S PUNCH'.",
        image: americanTrad
    },
    {
        id: 9,
        title: 'ENERGY DRINK #2: FINELINE',
        date: 'MARCH 13, 2024',
        description: "THE SECOND DESIGN IN A SERIES OF THREE. I CREATED A TATTOO INSPIRED ENERGY DRINK BRAND WITH THREE CAN DESIGNS BASED ON A STYLE OF TATTOOS. THIS IS 'FLORAL FIZZ'.",
        image: fineline
    },
    {
        id: 10,
        title: 'ENERGY DRINK #3: JAPANESE',
        date: 'MARCH 14, 2024',
        description: "THE THIRD DESIGN IN A SERIES OF THREE. I CREATED A TATTOO INSPIRED ENERGY DRINK BRAND WITH THREE CAN DESIGNS BASED ON A STYLE OF TATTOOS. THIS IS 'DRAGON'S BREATH'.",
        image: japanese
    },
    {
        id: 11,
        title: 'STAMP #4: BAUHAUS',
        date: 'MARCH 25, 2024',
        description: 'THE FOURTH STAMP IN A SERIES OF SEVEN STAMPS, SHOWCASING MY IDEA OF WHAT THE AIR JORDAN LOGO WOULD LOOK LIKE THROUGH VARIOUS DESIGN AND ARTISTIC MOVEMENTS THROUGHOUT HISTORY.',
        image: bauhaus
    },
    {
        id: 12,
        title: 'BRANDING: STATIONARY PACKAGE',
        date: 'MARCH 20, 2024',
        description: "MY BRANDED STATIONERY PACKAGE: A SEAMLESS BLEND OF MY IDENTITY WITH A FOCUS ON CLARITY AND PROFESSIONALISM. MAKES A LASTING IMPRESSION WITH MY LETTERHEAD, BUSINESS CARD, AND ENVELOPE DESIGN.",
        image: stationary
    },
    {
        id: 13,
        title: 'STAMP #5: INTERNATIONAL',
        date: 'APRIL 8, 2024',
        description: "THE FIFTH STAMP IN A SERIES OF SEVEN STAMPS, SHOWCASING MY IDEA OF WHAT THE AIR JORDAN LOGO WOULD LOOK LIKE THROUGH VARIOUS DESIGN AND ARTISTIC MOVEMENTS THROUGHOUT HISTORY.",
        image: international
    },
    {
        id: 14,
        title: 'STAMP #6: POST MODERN',
        date: 'APRIL 22, 2024',
        description: "THE SIXTH STAMP IN A SERIES OF SEVEN STAMPS, SHOWCASING MY IDEA OF WHAT THE AIR JORDAN LOGO WOULD LOOK LIKE THROUGH VARIOUS DESIGN AND ARTISTIC MOVEMENTS THROUGHOUT HISTORY.",
        image: postModern
    },
    {
        id: 15,
        title: 'STAMP #7: PERSONAL CHOICE (PUNK)',
        date: 'MAY 1, 2024',
        description: "THE SEVENTH AND FINAL STAMP IN A SERIES OF SEVEN STAMPS, SHOWCASING MY IDEA OF WHAT THE AIR JORDAN LOGO WOULD LOOK LIKE THROUGH VARIOUS DESIGN AND ARTISTIC MOVEMENTS THROUGHOUT HISTORY.",
        image: punk
    },
    {
        id: 16,
        title: 'BRANDING: MERCHANDISE PACKAGE',
        date: 'APRIL 29, 2024',
        description: "MY BRANDED MERCHANDISE PACKAGE: A SEAMLESS BLEND OF MY IDENTITY WITH A FOCUS ON CLARITY AND PROFESSIONALISM. MAKES A LASTING IMPRESSION WITH MOUSEPAD, FLASHDRIVE, PENS, MUG, STICKY NOTES, NOTEBOOK, AND RUBBER DUCK STRESS BALL.",
        image: merch
    },
]

export default design